<template>
  <div>
    <div class="container">
      <div class="row pt-5">
        <div class="col-12">
          <h1>Corte de caja | {{formattedDate}}</h1>
          <hr />
          <div v-for="(invoices, method) in groupedInvoices" :key="method" class="mb-5">
            <h2>Método de Pago: {{ method }}</h2>
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>Nombre</th>
                <th>NIT</th>
                <th>Placa</th>
                <th>Cambio</th>
                <th>Monto</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="invoice in invoices" :key="invoice.tax_id">
                <td>{{ invoice.name }}</td>
                <td>{{ invoice.tax_id }}</td>
                <td>{{ invoice.placa }}</td>
                <td>{{ formatCurrency(invoice.change) }}</td>
                <td>{{ formatCurrency(invoice.amount) }}</td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="4"><strong>Total</strong></td>
                <td><strong>{{ formatCurrency(calculateTotal(invoices, 'amount')) }}</strong></td>
              </tr>
              </tfoot>
            </table>
          </div>
          <hr />
          <div class="d-flex justify-content-center mt-4">
            <button class="btn btn-primary mx-2" @click="printReport">Imprimir Reporte</button>
            <button class="btn btn-success mx-2" @click="savePDF">Guardar PDF</button>
            <button class="btn btn-secondary mx-2" @click="returnToPOS">Regresar al POS</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js';

export default {
  data() {
    return {
      invoices: [],
      currentDate: new Date()
    };
  },
  computed: {
    formattedDate() {
      // Format date as dd/mm/yyyy hh:mm
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const date = this.currentDate.toLocaleDateString('en-GB', options);
      const time = this.currentDate.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
      });
      return `${date} ${time}`;
    },
    groupedInvoices() {
      // Group invoices by method
      return this.invoices.reduce((acc, invoice) => {
        if (!acc[invoice.method]) acc[invoice.method] = [];
        acc[invoice.method].push(invoice);
        return acc;
      }, {});
    },
  },
  methods: {
    calculateTotal(invoices, field) {
      // Calculate total for a specified field
      return invoices.reduce((sum, invoice) => sum + Number(invoice[field] || 0), 0);
    },
    formatCurrency(value) {
      // Format currency as Q50.00
      return `Q${Number(value).toFixed(2)}`;
    },
    printReport() {
      window.print();
    },
    savePDF() {
      const element = document.body;
      html2pdf().from(element).save('Corte_de_caja.pdf');
    },
    returnToPOS() {
      this.$router.push('/'); // Adjust the route to match your POS route
    }
  },
  async mounted() {
    // Load and parse invoices from localStorage
    const sessionInvoices = await JSON.parse(localStorage.getItem('session_invoices'));
    // eslint-disable-next-line no-unused-vars
    this.invoices = sessionInvoices.map(({ address, ...rest }) => rest); // Exclude 'address' field
  },
};
</script>

<style scoped>
/* Add any custom styling here if needed */
</style>
